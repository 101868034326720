/** @jsx jsx */
import { Box, Flex, useColorMode, jsx } from "theme-ui"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"

const Footer = () => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <Box as="footer" variant="footer">

      Copyright &copy; {new Date().getFullYear()}. <FormattedMessage id='footer_copyright_message' />
      <br />
      <Flex
        sx={{
          justifyContent: `center`,
          alignItems: `center`,
          mt: 3,
          color: `text`,
          fontWeight: `semibold`,
          a: { color: `text` },
        }}
      >
        <img width="90" height="30" src="../logo_metabravo.svg" alt="metabravo" />
        {` `}
      </Flex>
    </Box>
  )
}

export default Footer
