import React, { useState, useEffect }  from "react"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Projects from "../components/projects"
import About from "../components/about"
import Contact from "../components/contact"
import { window, exists } from "browser-monads"
import browserLang from 'browser-lang';
// import { langSelectorIsClicked } from "../components/language"
// import React, { useEffect } from "react";
// import { navigate } from "gatsby";


// breakpoints: [`400px`, `600px`, `900px`, `1200px`, `1600px`],
// The template is not responsive and the parallax effect brokens the containers.
// As a work around, use react hooks (useEffect) to calculate the size of the window
// and change number of pages and offset. This should be done calculating the size of each section first
// but in this version is hardcoded based on different tests


// const getRedirectLanguage = () => {

//     if (typeof navigator === `undefined`) {
//       return "en";
//     }

//     const lang = navigator && navigator.language && navigator.language.split("-")[0];
//     if (!lang) return "en";

//     switch (lang) {
//       case "pt-br":
//         return "pt";
//       default:
//         return "en";
//     }

// };

// const myLanguage = browserLang();


const Cara = () => {

   // Client-side Runtime fetch browser width
   const { pages, projectsOffset, projectsFactor, aboutOffset, aboutFactor, contactOffset, contactFactor } = useWindowWidth(); // Our custom Hook

  // const myLanguage = browserLang({
  //   languages: ['pt', 'en'],
  //   fallback: 'en',
  // });
  const myLanguage = browserLang();
  //console.log(`e o idioma de filho da puta do ano é ${myLanguage}`)

  //  useEffect(() => {
  //   const urlLang = getRedirectLanguage();
  //   // if (langSelectorIsClicked === false) {
  //   // console.log(`getRedirectLanguage reconhece o langSelectorIsClicked como ${langSelectorIsClicked}`);
  //     navigate(`/${urlLang}/`, {replace: true});
  //   // }
  //   }, []);

   return (
    <Layout>
      <Parallax pages={pages}>
        <Hero offset={0} factor={1} />
        <Projects offset={projectsOffset} factor={projectsFactor} />
        <About offset={aboutOffset} factor={aboutFactor} />
        <Contact offset={contactOffset} factor={contactFactor} />
      </Parallax>
    </Layout>
  )
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  let breakpoints = {
    pages: 5,
    projectsOffset: 2,
    projectsFactor: 2,
    aboutOffset:1,
    aboutFactor: 1,
    contactOffset:4,
    contactFactor: 1,
    width: width
  };

  if(exists(window)){
    useEffect(() => {

      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    });

    switch (true) {
      case (width <= 400):
        breakpoints.pages = 10;

        breakpoints.projectsOffset = 2;
        breakpoints.projectsFactor = 4;

        breakpoints.aboutOffset = 1;
        breakpoints.aboutFactor = 1;

        breakpoints.contactOffset = 6;
        breakpoints.contactFactor = 2;
        break;
      case (width <= 600):
        breakpoints.pages = 8;

        breakpoints.projectsOffset = 2;
        breakpoints.projectsFactor = 4;

        breakpoints.aboutOffset = 1;
        breakpoints.aboutFactor = 1;

        breakpoints.contactOffset = 6;
        breakpoints.contactFactor = 2;
        break;
      case (width <= 900):
        //using the default
        break;
      case (width <= 1200):
        //using the default
        break;
      case (width > 1600):
        //using the default
        break;
    }
  }

  // console.log("breakpoints", breakpoints.pages, breakpoints.projectsOffset, breakpoints.projectsFactor, breakpoints.aboutOffset, breakpoints.aboutFactor)

  return breakpoints;
}


export default Cara
