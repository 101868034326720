import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { FormattedMessage, Link, useIntl, FormattedHTMLMessage } from "gatsby-plugin-intl";
export const _frontmatter = {
  "title": "About"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2><FormattedMessage id="about_metabravo" mdxType="FormattedMessage" />{` `}<strong><span style={{
          "color": "#4553a4"
        }}>{`meta`}</span><span stye="color: white;">{`bravo`}</span></strong></h2>
    <blockquote><p><FormattedHTMLMessage id="about_mebravo_text1" mdxType="FormattedHTMLMessage" /></p></blockquote>
    <p><FormattedMessage id="about_mebravo_text2" mdxType="FormattedMessage" /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      