/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { hidden } from "../styles/utils"

const icons = {
    triangle: {
        shape: (

            <React.Fragment>
                <path d="M502.24,383.9c1.49,0,1.7-.82,1.7-1.55a11.78,11.78,0,0,0-.17-1.77,12.65,12.65,0,0,1-.16-1.74c0-1.93,1.26-2.75,3-2.75h.44v1.16h-.38c-1.24,0-1.66.67-1.66,1.8a9.65,9.65,0,0,0,.15,1.49,9.24,9.24,0,0,1,.15,1.6,2,2,0,0,1-1.51,2.27v0a2,2,0,0,1,1.51,2.29,8.94,8.94,0,0,1-.15,1.59,10,10,0,0,0-.15,1.51c0,1.18.49,1.83,1.66,1.83h.38v1.15h-.44c-1.74,0-3-.75-3-2.87a12.36,12.36,0,0,1,.16-1.72,11,11,0,0,0,.17-1.7c0-.65-.21-1.56-1.7-1.56Z" transform="translate(-502.24 -376.09)" />
                <path d="M513,385c-1.49,0-1.7.91-1.7,1.56a12.39,12.39,0,0,0,.17,1.7,12.37,12.37,0,0,1,.17,1.72c0,2.12-1.33,2.87-3.05,2.87h-.44v-1.15h.36c1.17,0,1.68-.65,1.68-1.83a13.53,13.53,0,0,0-.15-1.51,9.93,9.93,0,0,1-.17-1.59,2,2,0,0,1,1.52-2.29v0a2.05,2.05,0,0,1-1.52-2.27,10.31,10.31,0,0,1,.17-1.6,13,13,0,0,0,.15-1.49c0-1.13-.44-1.78-1.66-1.8h-.38v-1.16h.42c1.79,0,3.07.82,3.07,2.75a12.66,12.66,0,0,1-.17,1.74,13.25,13.25,0,0,0-.17,1.77c0,.73.21,1.55,1.7,1.55Z" transform="translate(-502.24 -376.09)" />
            </React.Fragment>
        ),
        viewBox: `0 0 30 30`,
    },
    circle: {
        shape: (
            <React.Fragment>
                <path d="M365.35,297.19l18.1-9.33v2.69L368.2,298.2v.08l15.25,7.64v2.69l-18.1-9.32Z" transform="translate(-365.35 -281.9)" /><path d="M386,310.17l10.88-28.27h2.65l-10.92,28.27Z" transform="translate(-365.35 -281.9)" /><path d="M420.07,299.33,402,308.61v-2.69l15.37-7.64v-.08L402,290.55v-2.69l18.1,9.29Z" transform="translate(-365.35 -281.9)" />
            </React.Fragment>
        ),
        viewBox: `0 0 30 30`,
    },
    arrowUp: {
        shape: (
            // <React.Fragment>
            //     <path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />
            //     {` `}
            //     <path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
            // </React.Fragment>

            <React.Fragment>
                <path d="M502.24,383.9c1.49,0,1.7-.82,1.7-1.55a11.78,11.78,0,0,0-.17-1.77,12.65,12.65,0,0,1-.16-1.74c0-1.93,1.26-2.75,3-2.75h.44v1.16h-.38c-1.24,0-1.66.67-1.66,1.8a9.65,9.65,0,0,0,.15,1.49,9.24,9.24,0,0,1,.15,1.6,2,2,0,0,1-1.51,2.27v0a2,2,0,0,1,1.51,2.29,8.94,8.94,0,0,1-.15,1.59,10,10,0,0,0-.15,1.51c0,1.18.49,1.83,1.66,1.83h.38v1.15h-.44c-1.74,0-3-.75-3-2.87a12.36,12.36,0,0,1,.16-1.72,11,11,0,0,0,.17-1.7c0-.65-.21-1.56-1.7-1.56Z" transform="translate(-502.24 -376.09)" />
                <path d="M513,385c-1.49,0-1.7.91-1.7,1.56a12.39,12.39,0,0,0,.17,1.7,12.37,12.37,0,0,1,.17,1.72c0,2.12-1.33,2.87-3.05,2.87h-.44v-1.15h.36c1.17,0,1.68-.65,1.68-1.83a13.53,13.53,0,0,0-.15-1.51,9.93,9.93,0,0,1-.17-1.59,2,2,0,0,1,1.52-2.29v0a2.05,2.05,0,0,1-1.52-2.27,10.31,10.31,0,0,1,.17-1.6,13,13,0,0,0,.15-1.49c0-1.13-.44-1.78-1.66-1.8h-.38v-1.16h.42c1.79,0,3.07.82,3.07,2.75a12.66,12.66,0,0,1-.17,1.74,13.25,13.25,0,0,0-.17,1.77c0,.73.21,1.55,1.7,1.55Z" transform="translate(-502.24 -376.09)" />
            </React.Fragment>
        ),
        viewBox: `0 0 30 42`,
    },
    upDown: {
        shape: (
            <React.Fragment>
                <path d="M366.4,308.61l1-7.64h-3.2v-2.3h3.55l.74-5.66h-3.36v-2.3h3.71l1-7.45h2.27l-1,7.45h4.06l1-7.45h2.3l-1,7.45h3.19V293h-3.54l-.71,5.66h3.36V301h-3.71l-1,7.64H372.8l1-7.64h-4.05l-1,7.64Zm7.76-9.94.74-5.66h-4l-.74,5.66Z" transform="translate(-364.22 -283.26)" />
            </React.Fragment>
        ),
        viewBox: `0 0 30 44.58`,
    },
    box: {
        shape: (
            <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
        ),
        viewBox: `0 0 30 30`,
    },
    hexa: {
        shape: (
            <polygon
                strokeLinejoin="round"
                strokeMiterlimit="10"
                points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 "
            />
        ),
        viewBox: `0 0 30 30`,
    },
    cross: {
        shape: (
            // <path
            //     strokeWidth="3px"
            //     d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
            // />

            <React.Fragment>
                <path d="M502.24,383.9c1.49,0,1.7-.82,1.7-1.55a11.78,11.78,0,0,0-.17-1.77,12.65,12.65,0,0,1-.16-1.74c0-1.93,1.26-2.75,3-2.75h.44v1.16h-.38c-1.24,0-1.66.67-1.66,1.8a9.65,9.65,0,0,0,.15,1.49,9.24,9.24,0,0,1,.15,1.6,2,2,0,0,1-1.51,2.27v0a2,2,0,0,1,1.51,2.29,8.94,8.94,0,0,1-.15,1.59,10,10,0,0,0-.15,1.51c0,1.18.49,1.83,1.66,1.83h.38v1.15h-.44c-1.74,0-3-.75-3-2.87a12.36,12.36,0,0,1,.16-1.72,11,11,0,0,0,.17-1.7c0-.65-.21-1.56-1.7-1.56Z" transform="translate(-502.24 -376.09)" />
                <path d="M513,385c-1.49,0-1.7.91-1.7,1.56a12.39,12.39,0,0,0,.17,1.7,12.37,12.37,0,0,1,.17,1.72c0,2.12-1.33,2.87-3.05,2.87h-.44v-1.15h.36c1.17,0,1.68-.65,1.68-1.83a13.53,13.53,0,0,0-.15-1.51,9.93,9.93,0,0,1-.17-1.59,2,2,0,0,1,1.52-2.29v0a2.05,2.05,0,0,1-1.52-2.27,10.31,10.31,0,0,1,.17-1.6,13,13,0,0,0,.15-1.49c0-1.13-.44-1.78-1.66-1.8h-.38v-1.16h.42c1.79,0,3.07.82,3.07,2.75a12.66,12.66,0,0,1-.17,1.74,13.25,13.25,0,0,0-.17,1.77c0,.73.21,1.55,1.7,1.55Z" transform="translate(-502.24 -376.09)" />
            </React.Fragment>
        ),
        viewBox: `0 0 100 100`,
    },
}

type IconType = "triangle" | "circle" | "arrowUp" | "upDown" | "box" | "hexa" | "cross"

type SVGProps = {
    stroke?: boolean
    color?: string | number | any
    width: number
    icon: IconType
    left: string
    top: string
    hiddenMobile?: boolean
}

const SVG = ({ stroke = false, color = ``, width, icon, left, top, hiddenMobile = false }: SVGProps) => (
    <svg
        sx={{
            position: `absolute`,
            stroke: stroke ? `currentColor` : `none`,
            fill: stroke ? `none` : `currentColor`,
            display: hiddenMobile ? hidden : `block`,
            color,
            width,
            left,
            top,
        }}
        viewBox={icons[icon].viewBox}
    >
        {icons[icon].shape}
    </svg>
)

export default SVG