import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { FormattedMessage, Link, useIntl, FormattedHTMLMessage } from "gatsby-plugin-intl";
export const _frontmatter = {
  "title": "Contact"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2><FormattedMessage id="contact_title" mdxType="FormattedMessage" /></h2>
    <p id='contato'><FormattedHTMLMessage id="contact_text" mdxType="FormattedHTMLMessage" /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      