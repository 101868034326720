import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import "./layout.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/meu.css"

import Header from "./header"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}



export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let langSelectorIsClicked = false;

  return (
  <div style={{}}>
  <Header siteTitle={data.site.siteMetadata.title} />

  {children}


  </div>
  )
}

