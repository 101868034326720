/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { ParallaxLayer } from "react-spring/renderprops-addons.cjs"
import { useState, useEffect } from 'react'

type ContentProps = {
  speed: number
  offset: number
  children: React.ReactNode
  className?: string
  factor?: number
}

const Content = ({ speed, offset, children, className = ``, factor = 1 }: ContentProps) => (

  // useEffect(() => {
  //   // your function that handles the scroll
  //   console.log('teste no paralax');
  //   // document.querySelector('div.css-zbbntt-Divider').parentElement.classList.add('parallax-child')
  //   // document.querySelector('div.parallax-child').parentElement.classList.add('parallax-main')
  //   // document.querySelector('div.parallax-main').parentElement.classList.add('parallax-grandpa')
  //   // document.querySelector('div.parallax-grandpa').addEventListener('scroll', handleScroll);
  // },[]);

   <ParallaxLayer
    sx={{
      padding: [3, 4, 4, 5],
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
      justifyContent: `center`,
      zIndex: 50,
    }}
    speed={speed}
    offset={offset}
    factor={factor}
    className={className}
    >
    {children}
  </ParallaxLayer>
)

export default Content
