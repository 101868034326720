import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap'
import ButtonGroup from 'react-bootstrap'

const languageName = {
  en: "English",
  pt: "Portuguese",
}

const Language = () => {
//   console.log(`Status do langSelectorIsClicked: ${langSelectorIsClicked}`);
  function clicouNoBotao (language){
    changeLocale(language);
    // langSelectorIsClicked = true;
    // console.log(`Status do langSelectorIsClicked: ${langSelectorIsClicked}`);
  }



  return (
    <div>

      <IntlContextConsumer>



        {({ languages, language: currentLocale }) =>
          languages.map(language => (





            <a class="seletoridioma"
              key={language}
              onClick={() => clicouNoBotao (language)}
              style={{
                color: currentLocale === language ? `red` : `lightseagreen;`,
                margin: 10,
                textDecoration: `underline`,
                cursor: `pointer`,
              }}
            >
              <img src={language === 'en' ? '../usa.svg' : '../brazil.svg'} width='20px' />

              {/* {languageName[language]} */}
            </a>

          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language