// import { Link, withAssetPrefix } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, Container } from 'react-bootstrap';
import { navigate } from '@reach/router';
import Scrollspy from 'react-scrollspy'
import scrollToElement from 'scroll-to-element';
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"
//import { AnchorLink } from "gatsby-plugin-anchor-links";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Language from "./language"


function scroller(target, offset) {
  scrollToElement(target, {
    offset,
  });
}

function handleMenuLinkClick(l, e) {
  if (typeof window !== 'undefined' && l.includes('#')) {
    const [anchorPath, anchor] = l.split('#');
    if (window.location.pathname === anchorPath) {
      e.preventDefault();
      scroller(`#${anchor}`, -80);
    }
  }
}

const Header = ({ siteTitle }) => (
  <header className="bg-light">
   <Container>

        <Navbar expand="md" bg="light" variant="light" fixed="top">
            <Navbar.Brand href="/"><img src="../logo_metabravo.svg" style={{ height:29, float:'right', marginLeft:10, dropShadow: "1px 3px 1px #ffffff" }} /></Navbar.Brand>
            <Language />
            <Navbar.Toggle aria-controls="navbarResponsive" />
            <Navbar.Collapse id="navbarResponsive">
                <Nav as="ul" className="ml-auto">
                    <Nav.Item as="li">
                        <Link id="menusobre" to="/#sobre" className="nav-link" activeClassName="active"><FormattedMessage id="about" /></Link>
                    </Nav.Item>

                    <Nav.Item as="li">
                        <Link id="menuoquefazemos" to="/#oquefazemos" className="nav-link" activeClassName="active"><FormattedMessage id="what_we_do" /></Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <a href="https://blog.metabravo.com" className="nav-link" activeClassName="active">Blog</a>
                    </Nav.Item>
                    <Nav.Item as="li">
                        <Link to="/#contato" className="nav-link" id="menucontato" activeClassName="active"><FormattedMessage id="contact" /></Link>
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
   </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header