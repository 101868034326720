/** @jsx jsx */
import { jsx } from "theme-ui"
import Divider from "../elements/divider"
import Inner from "../elements/inner"
import Content from "../elements/content"
import SVG from "./svg"
import { UpDown, UpDownWide } from "../styles/animations"
// @ts-ignore
import AboutMDX from "../sections/about"
// import React from 'react';
import React, { Fragment, Component } from "react"
import VisibilitySensor from 'react-visibility-sensor';



// console.log('teste');

// document.querySelector('div.css-zbbntt-Divider').parentElement.classList.add('parallax-child')
// document.querySelector('div.parallax-child').parentElement.classList.add('parallax-main')
// document.querySelector('div.parallax-main').parentElement.classList.add('parallax-grandpa')
// document.querySelector('div.parallax-grandpa').addEventListener('scroll', handleScroll);

type AboutProps = { offset: number; factor?: number }


export default class About extends Component <AboutProps, {}> {

  handleScroll(event) {
    // do something like call `this.setState`
    // access window.scrollY etc
    // console.log('ohmmmmmmmmmmm');
    // console.log('the scroll things')
  }

  // componentDidMount() {
  //   document.querySelector('div.css-zbbntt-Divider').parentElement.classList.add('parallax-child');
  //   document.querySelector('div.parallax-child').parentElement.classList.add('parallax-main');
  //   document.querySelector('div.parallax-main').parentElement.classList.add('parallax-grandpa');

  //   if (typeof window !== 'undefined') {
  //    // this.handleWindowSizeChange() // Set width
  //    console.log('ohmmmmmmmmmmm');
  //     // window.addEventListener('scroll', this.handleScroll)
  //     var el = document.querySelector('div.parallax-grandpa');
  //     if(el){
  //     el.addEventListener('scroll', this.handleScroll);
  //     }
  //   }
  // }
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    // document.querySelector('div.css-zbbntt-Divider').parentElement.classList.add('parallax-child');
    // document.querySelector('div.parallax-child').parentElement.classList.add('parallax-main');
    // document.querySelector('div.parallax-main').parentElement.classList.add('parallax-grandpa');

    // if (typeof window !== 'undefined') {
    //   var el = document.querySelector('div.parallax-grandpa');
    //   if(el){
    //   el.removeEventListener('scroll', this.handleScroll);
    //   }
    // }
  }



  // handleScroll = e => {
  //   //handle your event base on scroll in pixels or what ever for example :
  //   console.log(window.scrollY);
  //   console.log('ohmmmmmmmmmmm')
  //   if(window.scrollY > 100) {
  //     let aEl = document.getElementById('menusobre');
  //     aEl.setAttribute("style" , "color : red")
  //     console.log('eeeEEEeeeeEEEE')
  //   }
  // }

  render(){
    const { offset, factor = .99 } = this.props;
    return(
      <div>
        <Divider
          bg="divider"
          clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)"
          speed={0.2}
          offset={offset}
          factor={factor}
        />
        <Divider speed={0.1} offset={offset} factor={factor}>
          <UpDown>
            <SVG icon="box" hiddenMobile width={6} color="icon_blue" left="50%" top="75%" />
            <SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="70%" top="20%" />
            <SVG icon="triangle" width={8} stroke color="icon_darkest" left="25%" top="5%" />
            <SVG icon="upDown" hiddenMobile width={24} color="icon_orange" left="80%" top="80%" />
          </UpDown>
          <UpDownWide>
            <SVG icon="arrowUp" hiddenMobile width={16} color="icon_purple" left="5%" top="80%" />
            <SVG icon="triangle" width={12} stroke color="icon_brightest" left="95%" top="50%" />
            <SVG icon="circle" hiddenMobile width={6} color="icon_brightest" left="85%" top="15%" />
            <SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
          </UpDownWide>
          <SVG icon="circle" hiddenMobile width={6} color="icon_brightest" left="4%" top="20%" />
          <SVG icon="circle" width={12} color="icon_darkest" left="70%" top="60%" />
          <SVG icon="box" width={6} color="icon_orange" left="10%" top="10%" />
          <SVG icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
          <SVG icon="hexa" width={8} stroke color="icon_darkest" left="80%" top="70%" />
        </Divider>
        <Content speed={0.05} offset={offset + 0.01} factor={factor}>
          <Inner>
          <VisibilitySensor>
            {({isVisible}) =>
            <Fragment>
            <div id="sobre"><img src="../1x1-00ff007f.png"/>{isVisible ? document.getElementById('menusobre').classList.add('menuativo') : document.getElementById('menusobre').classList.remove('menuativo')} </div>

              <AboutMDX/>
            </Fragment>
            }
          </VisibilitySensor>

          </Inner>
        </Content>
      </div>
      )
  }
}

// export default About
