import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { FormattedMessage, Link, useIntl, FormattedHTMLMessage } from "gatsby-plugin-intl";
export const _frontmatter = {
  "title": "Projects"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2><div style={{
        "textShadow": "1px 1px 1px black"
      }}><FormattedMessage id="what_we_do_title" mdxType="FormattedMessage" /></div></h2>
    <ProjectCard title=<FormattedMessage id='what_we_do_content.first_block_title' mdxType="FormattedMessage" /> bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  <div align="center" style={{
        "width": "100%"
      }}><img width="190" height="190" src="../automation.svg" /></div>
   <div align="center" style={{
        "width": "100%"
      }}><FormattedMessage id='what_we_do_content.first_block_content' mdxType="FormattedMessage" /> </div>
    </ProjectCard>
    <ProjectCard title=<FormattedMessage id='what_we_do_content.second_block_title' mdxType="FormattedMessage" /> bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  <div align="center" style={{
        "width": "100%"
      }}><img width="190" height="190" src="../maintenance.svg" /></div>
   <div align="center" style={{
        "width": "100%"
      }}><FormattedMessage id='what_we_do_content.second_block_content' mdxType="FormattedMessage" /></div>
    </ProjectCard>
    <ProjectCard title=<FormattedMessage id='what_we_do_content.third_block_title' mdxType="FormattedMessage" /> bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  <div align="center" style={{
        "width": "100%"
      }}><img width="190" height="190" src="../development.svg" /></div>
   <div align="center" style={{
        "width": "100%"
      }}><FormattedMessage id='what_we_do_content.third_block_content' mdxType="FormattedMessage" /></div>
    </ProjectCard>
    <ProjectCard title=<FormattedMessage id='what_we_do_content.fourth_block_title' mdxType="FormattedMessage" /> bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  <div align="center" style={{
        "width": "100%"
      }}><img width="190" height="190" src="../linux.svg" /></div>
   <div align="center" style={{
        "width": "100%"
      }}><FormattedMessage id='what_we_do_content.fourth_block_content' mdxType="FormattedMessage" /></div>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      